<template>
  <div class="flex items-center">
    <button
      v-for="language in locales"
      :key="language.code"
      class="px-2 font-bold uppercase underline-offset-8 dark:text-white"
      :class="[language.code === locale ? 'underline': 'opacity-50']"
      type="button"
      @click="setLocaleCookie(language.code); $router.go()"
    >
      {{ language.code }}
    </button>
  </div>
</template>

<script lang="ts" setup>
const {locales, setLocaleCookie, locale} = useI18n();
</script>
