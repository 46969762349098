<template>
  <div class="relative">
    <AppHeader :is-homepage="['/', '/bedankt'].includes($route.path)" />
    <slot />
    <AppFooter />

    <div
      v-if="release"
      class="pointer-events-none fixed bottom-0.5 right-1 z-50 text-xs"
    >
      {{ release.substring(0, 6) }}
    </div>
  </div>
</template>

<script setup lang="ts">
const {public: {release}} = useRuntimeConfig();
</script>

