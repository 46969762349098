<template>
  <div class="hidden gap-x-4 lg:flex">
    <NuxtLink
      v-for="(link, index) in menu"
      :key="index"
      :to="link.url"
    >
      {{ link.label }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const {menu} = useSofieMenu('MainMenu');
</script>
