<template>
  <div class="bg-primary dark absolute inset-y-0 right-0 top-full z-50 h-[calc(100vh_-_70px)] w-full pb-8 pt-2 transition duration-150">
    <div class="container flex h-full flex-col justify-between">
      <AppMenuLanguageSwitcher />
      <div>
        <NuxtLink
          v-for="(link, index) in menu"
          :key="index"
          class="flex items-center justify-between px-10 py-5 text-white"
          :to="link.url"
        >
          {{ link.label }}
          <UiIcon
            name="material-symbols:chevron-right"
            size="small"
          />
        </NuxtLink>
      </div>
      <UiButton
        type="tertiary"
        class="mt-20"
        :label="$t('global.customer_service_button')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const {menu} = useSofieMenu('MobileMenu');
</script>
